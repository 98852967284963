import React, { useState } from "react";
import Spinner from "./Spinner";

const GoogleFormEmbed: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: loading ? "30vh" : "100vh",
        position: "relative",
      }}
    >
      {loading && <Spinner />}
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfWUl8fPMeG7DZV1snQeV-SPI9I7ibadK0rSN5MZQfzwRAF1w/viewform?embedded=true"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        title="Google Form"
        onLoad={handleLoad}
      />
    </div>
  );
};

export default GoogleFormEmbed;
